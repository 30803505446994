<template>
	<br />
</template>

<script>
	export default {
		name: 'Success',
		mounted() {
			this.mountedFunc();
		},
		methods: {
			mountedFunc() {
				const localStorageKey = 'orderIdForSuccessPage';
				const orderId = localStorage.getItem(localStorageKey);
				localStorage.setItem(localStorageKey, '');
				this.$router.push(`/thank-you/${orderId}`);
			}
		}
	};
</script>

<style scoped></style>
